import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n.js'
import apolloClient from '@/apolloClient'
import moment from "moment"
import authStore from './modules/authStore/store'
import uiStore from './modules/uiStore/store'
import countriesStore from './modules/countriesStore/store'
import countryStore from './modules/countryStore/store'
import entryRestrictionStore from './modules/entryRestrictionStore/store'
import entryRestrictionsStore from './modules/entryRestrictionsStore/store'
import entryRestrictionWithParentStore from './modules/entryRestrictionWithParentStore/store'
import countriesEntryRestrictionsStore from './modules/countriesEntryRestrictionsStore/store'
import entryRestrictionAuthoritiesStore from './modules/entryRestrictionAuthoritiesStore/store'
import touroperatorExternalRestrictionInfosStore from './modules/touroperatorExternalRestrictionInfosStore/store'
import bosysStore from './modules/bosysStore/store'
import mapCountriesStore from './modules/mapCountriesStore/store'
//import entryRestrictionLatestSignificantUpdatesStore from './modules/entryRestrictionLatestSignificantUpdatesStore/store'
import organisationCountrySelectionsStore from './modules/organisationCountrySelectionsStore/store'
import organisationUserStore from './modules/organisationUserStore/store'
import organisationStore from './modules/organisationStore/store'
import newsStore from './modules/newsStore/store'
import dmNewsStore from './modules/dmNewsStore/store'
import newsTagsStore from './modules/newsTagsStore/store'
import tickerStore from './modules/tickerStore/store'
import eventsLatestSignificantUpdatesStore from './modules/eventsLatestSignificantUpdatesStore/store'
import eventsLatestSignificantUpdatesPinnedStore from './modules/eventsLatestSignificantUpdatesPinnedStore/store'
import eventsStore from './modules/eventsStore/store'
import mapEventsStore from './modules/mapEventsStore/store'
import eventsCategoriesStore from './modules/eventsCategoriesStore/store'
import textSnippetsStore from './modules/textSnippetsStore/store'
import gmEventCovidUpdateStore from './modules/gmEventCovidUpdateStore/store'

import { generatedEntryRestrictionDetailTypesCategories, generatedEntryRestrictionDetailTypes } from '../generated/store/details';

Vue.use(Vuex)

/* eslint-disable no-unused-vars */
export default new Vuex.Store({
  state: {
    loading: false,

    // every running query increase the number by 1, and finished/failed ones decrease it by one.
    // Loading is true as long as the number is > 1
    queriesInProgress: 0,

    // Contains a list of error messages that can/must be shown in the frontend
    // Every error must be a dict with at least these fields:
    // {
    //    id: # random value, must be unique
    //    message: # string message, best in user language
    // }
    errors: [],

    // filter for EntryRestrictionsView
    entryRestrictionsViewFilters: {
      countryNameSearch: "",
      dateFilterOption: "ALL",
      dateFilterDate: moment().format("YYYY-MM-DD"),
      addDialogOpen: false,
      addDialogCountry: null,
    },

    entryRestrictionDetailTypesCategories: generatedEntryRestrictionDetailTypesCategories,
    entryRestrictionDetailTypes: generatedEntryRestrictionDetailTypes,

  },
  getters: {
    entryRestrictionDetailTypesByCategory(state) {
      // Groups types by category
      let rs = Object.entries(state.entryRestrictionDetailTypes).reduce((groups, detailData) => {
        let typeCode = detailData[0]
        let detailType = detailData[1]

        let categoryCode = detailType['category']
        const categoryData = state.entryRestrictionDetailTypesCategories[categoryCode]

        if (!groups[categoryCode]) {
          groups[categoryCode] = {
            data: categoryData,
            types: {}
          };
        }
        groups[categoryCode]['types'][typeCode] = detailType
        return groups;
      }, {});
      return rs
    }
  },
  mutations: {
    INCREASE_QUERIESINPROGRESS: (state) => {
      state.queriesInProgress++

      if (state.queriesInProgress > 0) {
        state.loading = true
      }
    },
    DECREASE_QUERIESINPROGRESS: (state) => {
      state.queriesInProgress--

      if (state.queriesInProgress == 0) {
        state.loading = false
      }
    },

    ADD_ERROR: (state, error) => {
      state.errors.push(error)
    },

    SET_ENTRYRESTRICTIONSVIEWFILTERS: (state, entryRestrictionsViewFilters) => {
      state.entryRestrictionsViewFilters = entryRestrictionsViewFilters
    },

  },
  actions: {

    /**
     * Query GraphQL via Apollo, but also does some local work, like handling the loading
     * animation or managing errors
     */
    async query({ commit, dispatch }, params) {
      let refreshUser = true
      if (params && params.refreshUser !== undefined) {
        refreshUser = params.refreshUser
      }

      return new Promise((resolve, reject) => {
        commit('INCREASE_QUERIESINPROGRESS')

        apolloClient.query(params)
        .then(result => {
          commit('DECREASE_QUERIESINPROGRESS')

          // if no graphQL Exception occured, it can still have graphene errors
          if (result.errors && result.errors.length > 0) {
            if (params.allowGlobalError === undefined || params.allowGlobalError == true) {
              commit('ADD_ERROR', {
                id: '_' + Math.random().toString(36).substr(2), // create a unique id
                message: result.errors[0].message
              })
            }

            // we reload the user on error, as it might be part of a login problem
            if (refreshUser) {
              dispatch('authStore/refreshUser')
            }

            reject(result)
          } else {
            resolve(result)
          }
        }).catch(error => {
          commit('DECREASE_QUERIESINPROGRESS')

          // TODO BETTER ERROR MESSAGES!
          if (params.allowGlobalError === undefined || params.allowGlobalError == true) {
            commit('ADD_ERROR', {
              id: '_' + Math.random().toString(36).substr(2), // create a unique id
              message: "Ein Fehler ist aufgetreten"
            })
          }

          // we reload the user on error, as it might be part of a login problem
          if (refreshUser) {
            dispatch('authStore/refreshUser')
          }

          reject(error)
        })
      })
    },
  },

  modules: {
    authStore,
    uiStore,

    // CRUD Stores
    countriesEntryRestrictionsStore,
    entryRestrictionStore,
    entryRestrictionsStore,
    entryRestrictionWithParentStore,
    entryRestrictionAuthoritiesStore,
    touroperatorExternalRestrictionInfosStore,
    //entryRestrictionLatestSignificantUpdatesStore,
    organisationCountrySelectionsStore,
    eventsLatestSignificantUpdatesStore,
    eventsLatestSignificantUpdatesPinnedStore,
    eventsStore,
    mapEventsStore,
    eventsCategoriesStore,
    newsStore,
    newsTagsStore,
    tickerStore,
    countriesStore,
    countryStore,
    textSnippetsStore,
    gmEventCovidUpdateStore,
    organisationUserStore,
    organisationStore,
    bosysStore,
    mapCountriesStore,
    dmNewsStore
  }
})
/* eslint-enable no-unused-vars */