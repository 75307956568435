import router from '../../../router'
import apolloClient from '../../../apolloClient'
import { WHOAMI_QUERY } from "./graphql";
import { TOKENAUTH_MUTATION } from "./graphql";

const store = {
  namespaced: true,

  state: {
    user: {
      id: '',
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      isStaff: false,
      cdbAccessKey: '',
    },
    organisation: {},
    permissions: [],
    authToken: '',
  },

  getters: {
    versionKey: (state, getters, rootState) => {
      return process.env.VUE_APP_DM_VERSION_KEY.trim()
    },

    cdbUrl: (state, getters, rootState) => {
      if (!state.user.cdbAccessKey) {
        return ''
      }
      return 'https://global-monitoring.com/cdb/' + state.user.cdbAccessKey +'?lang=' + rootState.uiStore.language
    },

    cdbRiskMatrixUrl: (state, getters, rootState) => {
      if (!state.user.cdbAccessKey) {
        return ''
      }
      return 'https://global-monitoring.com/cdb/overview/' + state.user.cdbAccessKey + '?lang=' + rootState.uiStore.language
    },

  },

  mutations: {
    SET_AUTHTOKEN: (state, authToken) => {
      state.authToken = authToken
      localStorage.setItem("authToken", authToken)
    },

    SET_USER: (state, user) => {
      state.user = user
    },

    SET_ORGANISATION: (state, organisation) => {
      state.organisation = organisation
    },

    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
  },

  actions: {
    initStore({ commit, dispatch }) {
      if (localStorage.getItem("authToken")) {
        commit("SET_AUTHTOKEN", localStorage.getItem("authToken"))
        dispatch("refreshUser")
      }
    },

    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const username = data.username
        const password = data.password

        apolloClient
          .mutate({
            mutation: TOKENAUTH_MUTATION,
            variables: {
              username,
              password
            }
          })
          .then(result => {
            const token = result.data.tokenAuth.token
            commit("SET_AUTHTOKEN", token)

            dispatch("refreshUser")
            resolve()
          })
          .catch(error => {
            reject(error)
          });
      })
    },

    async logout({ commit, dispatch }) {
      dispatch("clearUser")
      router.push("/login");
    },

    async clearUser({ commit, dispatch }) {
      commit("SET_AUTHTOKEN", "")
      commit('SET_USER', {
        id: '',
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        isStaff: false,
        cdbAccessKey: '',
      })
      commit('SET_ORGANISATION', [])
      commit('SET_PERMISSIONS', [])
    },

    async refreshUser({ commit, dispatch }) {
      let whoami = null
      try {
        whoami = await this.dispatch("query", {
          query: WHOAMI_QUERY,
          refreshUser: false // no endless loop, please...
        })
      } catch(error) {
        // if we got an correct message (= no network problem), but without any user content, then we
        // handle it as an account problem = logout
        if (error && error.data && error.data.whoAmI !== undefined && error.data.whoAmI == null) {
          // do not use "logout" here, or you get stuck in an endless loop
          dispatch("clearUser")
          router.push("/login");
        }
      }

      if (whoami) {
        commit('SET_USER', {
          id: whoami.data.whoAmI.id,
          username: whoami.data.whoAmI.username,
          firstName: whoami.data.whoAmI.firstName,
          lastName: whoami.data.whoAmI.lastName,
          email: whoami.data.whoAmI.email,
          isStaff: whoami.data.whoAmI.isStaff,
          cdbAccessKey: whoami.data.whoAmI.cdbAccessKey,
        })
        commit('SET_ORGANISATION', whoami.data.whoAmI.organisation)
        commit('SET_PERMISSIONS', whoami.data.whoAmI.permissions)

        // we also load the news on start, as we need them systemwide
        // We can not trigger this in the news module, as we might do it before an authToken was aquired
        dispatch("uiStore/fetchNewsAndLatestUpdates", {}, {root: true})

        // test of we got a valid version information from the server. If yes, we can compare them with our local version
        let dmVersionKey = whoami.data.whoAmI.dmVersionKey.trim()
        let localVersionKey = process.env.VUE_APP_DM_VERSION_KEY.trim()
        if (dmVersionKey != '' && dmVersionKey.length > 2 && dmVersionKey != 'DEV') {
          // local key should be set with a valid key, too!
          if (localVersionKey != '' && localVersionKey.length > 2 && localVersionKey != 'DEV') {
            // if both keys are valid, are they identical? If not, reload the page!
            console.log("Server version:" + dmVersionKey)
            console.log("Client version:" + localVersionKey)
            if (dmVersionKey != localVersionKey) {
              // we do the reload after 2 seconds. Reason: We might still be in the middle of the deployment, and
              // a quick reload might create problems
              console.log("Server and Client have different version. Starting autoreload.")
              setTimeout(() => { window.location.reload(true) }, 2000);
            } else {
              console.log("Server and Client have the same version.")
            }
          }
        }
      }
    },
  }
}

export default store