import gql from 'graphql-tag'

export const DM_NEWS_QUERY = gql`
query DmNews{
  dmNews {
    results {
      id
      text
      textDe
      textEn
      title
      titleDe
      titleEn
      importantUntil
      newsType
      tags {
        name
        order
      }
      country {
        id
        nameShort
        iso2
      }
      image
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      modifiedAt
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateAt
    }
  }
}
`