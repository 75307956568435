import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { NEWS_QUERY, NEWS_CREATE_MUTATION, NEWS_UPDATE_MUTATION, NEWS_DELETE_MUTATION} from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: NEWS_QUERY,
    fetchAllFieldName: 'news',

    createMutation: NEWS_CREATE_MUTATION,
    createMutationName: 'createNews',
    createFieldName: 'news',

    updateMutation: NEWS_UPDATE_MUTATION,
    updateMutationName: 'updateNews',
    updateFieldName: 'news',

    deleteMutation: NEWS_DELETE_MUTATION,
    deleteMutationName: 'deleteNews',
    deleteFieldName: 'news',
  },
  getters: {
    newsWithIsUnread(state, getters, rootState) {
      let news = []
      let lastVisitDate = localStorage.getItem('lastNewsVisitDate')
      for (let entry of state.entries) {
        entry.isUnread = false
        entry.isNew = false
        if (moment(entry.createdAt) > moment(lastVisitDate)) {
          entry.isUnread = true
          entry.isNew = true
        }
        news.push(entry)
      }
      return news
    },
  }
})
/* eslint-enable no-unused-vars */

export default store

